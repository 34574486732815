import React, { useContext, useRef } from 'react'
import CSSModules from 'react-css-modules'
import styles from './ModalSecondView.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeModal from './changeModal'
import { InvoiceModalValues , InvoiceProps } from './ModalFirstView'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import ModalButton from 'spa/member/components/ModalButton'
import InvoiceRequestedImg from '../../../../../assets/images/invoice/invoice_requested.png'
import MemberTechCompanyName  from '../../../../spa/member/components/MemberTechCompanyName'

interface ChangeModalProps extends ChangeModal {
  name: string,
  companyNameFlag: boolean
}

const ModalSecondView: React.FC<ChangeModalProps> = CSSModules<React.FC<ChangeModalProps>>((props) => {
  const context = useContext(InvoiceModalValues)
  const inputRef = useRef(null)

  const handleStartClick = () => {
    inputRef.current.focus()
  }

  const checkCharAndType = (event: string) => {
    const noNumeric = /[^0-9]/.test(event)
    const valid = event.length === 13 && !noNumeric
    context.setIsValid(valid)
    context.setGoNextBtn(valid)
  }

  const setNumber = ( event: string ) => {
    context.setInvoiceNumber(event)
    checkCharAndType(event)
  }

  const closeModal = () => {
    props.changeModal('')
    context.setErrors([])
    context.setIsValid(true)
    context.setGoNextBtn(false)
    context.setInvoiceNumber('')
  }

  //TODO: 入力エラー、APIエラーのレスポンスが決まり次第値を調整
  const errorsValue = {
    not_found: {
      title: '入力内容をご確認ください',
      description: '登録内容の確認が取れませんでした。国税庁に届け出ている情報を確認し、もう一度登録をしなおしてください。'
    },
    api_error: {
      title: 'しばらく時間をおいてお試しください',
      description: 'インボイス登録番号の検証ができませんでした。しばらく時間をおいてからもう一度お試しください。'
    }
  }

  const errorType = context.errors[0] as keyof typeof errorsValue
  const errorTitle = errorsValue[errorType]?.title
  const errorDescription = errorsValue[errorType]?.description
  const displayNameType = props.companyNameFlag ? '法人名' : '氏名'

  const errorBoX = () => {
    return (
      <div styleName='error-box'>
        <div styleName='error-title'><p>{ errorTitle }</p></div>
        <div styleName='error-description'><p>{ errorDescription }</p></div>
      </div>
    )
  }

  const validateBox = () => {
    return (
      <div styleName='validate-box'>
        <div styleName='validate-icon'><FontAwesomeIcon icon={faXmark} color='#e03100'/></div>
        <div styleName='validate-description' className='invoice-validate-description'><p>T＋13桁の半角数字を入力してください</p></div>
      </div>
    )
  }

  return (
    <div styleName='overlay'>
      <div styleName='modal-container' className='js-modal-container'>
        <div styleName='section-title'><p>インボイス登録番号をお聞かせください。</p></div>
        <div styleName='section-divider'></div>
        {context.errors.some(error => ['not_found', 'api_error'].includes(error)) && errorBoX()}
        <div styleName='section-text'>
          <div styleName='text'>
            <p>入力いただいた方には、インボイス登録番号を記載したお支払内容をお渡しします。</p>
          </div>
        </div>
        <div styleName='section-invoice-description'>
          <p styleName='text'><MemberTechCompanyName />に登録されている{displayNameType}が適格請求書発行事業者の氏名又は名称と同一かご確認ください。<MemberTechCompanyName />に登録されている{displayNameType}の変更が難しい場合は<a styleName='inquiry-text' href='/inquiry' target='_blank'>お問い合わせ</a>ください。</p>
          <img styleName='section-invoice-img' alt='国税庁へ届け出た申請書類の見本' src={InvoiceRequestedImg} />
          <p styleName='gray-img-description'>国税庁へ届け出た申請書類</p>
        </div>
        <div styleName='section-name-description'>
          <p>適格請求書発行事業者 氏名又は名称（<MemberTechCompanyName />に登録されている{displayNameType}を表示しています）</p>
        </div>
        <div styleName='section-profile'>
          <p styleName='current-profile-name'>{props.name}</p>
          <a styleName='input-profile-link' href='/profile_sheet/basic_profile'><MemberTechCompanyName />に登録されている{displayNameType}を編集</a>
        </div>
        <div styleName='section-contents'>
          <label htmlFor='input-number' styleName='content-title'>インボイス登録番号</label>
          <div styleName={`content-item ${!context.isValid? 'input-error' : ''}`}>
            <span styleName='t-start' onClick={handleStartClick}>T</span>
            <input
              type='text'
              id='input-number'
              ref={inputRef}
              placeholder='0000000000000'
              value={context.invoiceNumber || ''}
              onChange={event => setNumber(event.target.value)}
              styleName='input-number'
              className='input-invoice-number'
              required
              pattern="\d{13}"
            >
            </input>
          </div>
          { !context.isValid && validateBox() }
          <div styleName='content-description'>※インボイス登録番号は「T＋法人番号」、それ以外の事業者は「T＋新たな13桁の数字の固有番号」です。<br/>
            ※13桁の半角数字でご記入ください。<br/>
            <span>※必ずご自身のインボイス登録番号をご記入ください。</span></div>
        </div>
        <div styleName='section-btn'>
          <ModalButton buttonType='BACK' onClick={() => closeModal()}>キャンセル</ModalButton>
          <ModalButton buttonType={context.goNextBtn ? 'NEXT' : 'DISABLED'} onClick={() => props.changeModal('thirdModal')} className='second-modal-go-next-btn' disabled={!context.goNextBtn}>確認する</ModalButton>
        </div>
      </div>
    </div>
  )
}, styles, { allowMultiple: true })

export { ModalSecondView }
