import { DefaultTheme } from 'styled-components'

const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
}

const appTheme: DefaultTheme = {
  media: {
    sm: `(min-width: ${breakpoints.sm})`,
    md: `(min-width: ${breakpoints.md})`,
    lg: `(min-width: ${breakpoints.lg})`,
    xl: `(min-width: ${breakpoints.xl})`,
  },
  clients: {
    colors: {
      white: '#ffffff',
      black: '#222222',
      silver: {
        _10: '#F7F8FA',
        _30: '#DBDDE3',
        _50: '#C0C4CE',
        _70: '#A6AAB6',
        _100: '#6E7585',
      },
      lightGreen: '#0bbe78',
      greenishTeal: '#4fd7a2',
      gray: '#777777',
      green: '',
      red: '#ff0000',
      orange: {
        _10: '',
        _30: '',
        _50: '#EB592B',
        _70: '',
        _100: '',
      },
    }
  },
  members: {
    colors: {
      lightGreen: '#0BBE78',
      white: '#ffffff',
      black: '#222222',
      gray: '#777777',
      green: '#0BBE78',
      appleGreen: {
        _10: '#E7FAEC',
        _90: '#21993D',
      },
      emeraldGreen: '#52AB88',
      red: '#f15252',
      pink: '#ffeded',
      orange: {
        _50: '#EB592B',
        _80: '#E03100',

      },
      lightGray: '#DBDDE3',
      silver: {
        _10: '#F7F8FA',
        _30: '#DBDDE3',
        _50: '#C0C4CE',
        _70: '#A6AAB6',
        _100: '#6E7585',
      },
    },
    flash: {
      colors: {
        success: 'rgba(6, 170, 106, 0.8)',
        notice: 'rgba(6, 170, 106, 0.8)',
        alert: 'rgba(241, 82, 82, 0.8)',
        error: 'rgba(241, 82, 82, 0.8)',
      }
    }
  },
}

export { appTheme }
