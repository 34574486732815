import * as React from 'react'
import Project from './Project'

interface HeaderNavbarNavItemWorkReportProps {
  projects: Project[];
}

const HeaderNavbarNavItemWorkReport: React.FC<HeaderNavbarNavItemWorkReportProps> = props => {
  if (!props.projects) return null
  if (!props.projects.length) return null

  const linkList = (
    <ul className="header-nav-dropdown-list">
      {
        props.projects.map((project, index) =>
          <li key={index} className="header-nav-item header-nav-item-work-report">
            <a className="header-nav-link" href={project.monthly_work_reports_url} data-turbo="false">
              <span>{project.title}</span>
              <span>{`${project.start_on}〜${project.end_on}`}</span>
            </a>
          </li>
        )
      }
    </ul>
  )

  const navListDrawer = (
    <>
      <h6 className="header-nav-dropdown-header">
        作業報告書
      </h6>
      {linkList}
    </>
  )

  const navListDropdown = (
    <>
      {linkList}
    </>
  )

  const drawer = () => {
    const toggler = (
      <label className="header-drawer-toggler" htmlFor="headerDrawerToggleWorkReport">
        <span className="header-nav-toggler-work-report">
          作業報告書
        </span>
      </label>
    )

    const dropdownMenu = (
      <div className="header-nav-dropdown-menu header-nav-drawer-menu">
        {navListDrawer}
      </div>
    )

    return (
      <>
        <div className="header-nav-drawer-work-report" tabIndex={-1} aria-hidden="true">
          <input className="header-drawer-toggle" type="checkbox" id="headerDrawerToggleWorkReport" aria-hidden="true" />
          <label className="header-drawer-backdrop" htmlFor="headerDrawerToggleWorkReport" aria-hidden="true" />
          {toggler}
          <div className="header-drawer-content">
            {dropdownMenu}
          </div>
        </div>
      </>
    )
  }

  const dropdown = () => {
    const toggler = (
      <button
        type="button" className="header-dropdown-toggler"
        data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false"
      >
        <span className="header-nav-toggler-work-report">
          作業報告書
        </span>
      </button>
    )

    const dropdownMenu = (
      <div className="header-nav-dropdown-menu header-nav-dropdown-menu-work-report">
        {navListDropdown}
      </div>
    )

    return (
      <>
        <div className="header-nav-dropdown-work-report">
          {toggler}
          <div className="dropdown-menu header-dropdown-menu">
            {dropdownMenu}
          </div>
        </div>
      </>
    )
  }

  const navItem = (
    <>
      <div className="header-nav-work-report">
        {drawer()}
        {dropdown()}
      </div>
    </>
  )

  return (
    <>
      {navItem}
    </>
  )
}

export default HeaderNavbarNavItemWorkReport
