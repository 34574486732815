import * as React from 'react'
import * as Routes from 'routes'
import HeaderNavItem from './HeaderNavItem'

const HeaderNavbarNav: React.FC = () => {

    const isRootPath = location.pathname == Routes.root_path()

    const navItemAbout = (
        <HeaderNavItem path={ isRootPath ? '#front-feature' : Routes.root_path({ anchor: 'front-feature' })} text='サービスの特徴' smoothscroll={isRootPath}/>
    )

    const navItemUsage = (
        <HeaderNavItem path={isRootPath ? '#front-usage' : Routes.root_path({ anchor: 'front-usage' })} text='ご利用の流れ' smoothscroll={isRootPath}/>
    )

    const navItemFaq = (
        <HeaderNavItem path={isRootPath ? '#front-faq' : Routes.root_path({ anchor: 'front-faq' })} text='よくある質問' smoothscroll={isRootPath}/>
    )

    const navItemUsefulInfo = (
        <HeaderNavItem path={isRootPath ? '#useful-info' : Routes.root_path({ anchor: 'useful-info' })} text='お役立ち情報' smoothscroll={isRootPath}/>
    )


    const navItemjobOffers = (
        <HeaderNavItem
            path={Routes.job_offers_path()} text='掲載案件'
            itemClassName='header-nav-item-job-offers'
        />
    )

    // Indeedの掲載審査のため、新規会員登録のリンクに案件IDのパラメータを付与する
    const currentJobOfferId = location.pathname.match(/job_offers\/(\d+)/)?.[1]
    const newUserPathParams = currentJobOfferId ? { job_offer_id: currentJobOfferId } : {}

    const navItemNewUser = (
        <HeaderNavItem
            path={Routes.new_user_path({ ...newUserPathParams, ref: 'sp_header' })} text='無料登録'
            itemClassName='header-nav-item-signup' linkClassName='header-nav-link-signup'
        />
    )

    const navItemNewUserLg = (
        <HeaderNavItem
            path={Routes.new_user_path(newUserPathParams)} text='無料登録する'
            itemClassName='header-nav-item-signup' linkClassName='header-nav-link-signup'
        />
    )

    const navItemLogin = (
        <HeaderNavItem
            path={Routes.login_path()} text='ログイン'
            itemClassName='header-nav-item-login' linkClassName='header-nav-link-login'
        />
    )

    const navItemLoginLg = (
        <HeaderNavItem
            path={Routes.login_path()} text='ログインする'
            itemClassName='header-nav-item-login' linkClassName='header-nav-link-login'
        />
    )

    const navItemClient = (
        <li className="header-nav-item header-nav-item-client">
            <a className="header-nav-link header-nav-link-client"
               href="https://crowdtech.jp/client/" /* direct routesはjs-routesに渡せない模様 */>
                フリーランスを
                <br/>
                お探しの企業の方
            </a>
        </li>
    )

    const navListNavbar = (
        <>
            <ul className="header-navbar-nav-list header-navbar-nav-list-register">
                {navItemNewUser}
                {navItemLogin}
            </ul>
        </>
    )

    const navListNavbarLg = (
        <>
            <ul className="header-navbar-nav-list header-navbar-nav-list-lg">
                {navItemAbout}
                {navItemUsage}
                {navItemFaq}
                {navItemUsefulInfo}
                {navItemjobOffers}
            </ul>
            <ul className="header-navbar-nav-list header-navbar-nav-list-lg header-navbar-nav-list-register">
                {navItemNewUserLg}
                {navItemLoginLg}
                {navItemClient}
            </ul>
        </>
    )

    const navListDrawer = (
        <>
            <ul className="header-nav-dropdown-list header-nav-dropdown-list-guest">
                {navItemAbout}
                {navItemUsage}
                {navItemFaq}
                {navItemUsefulInfo}
                {navItemjobOffers}
                {navItemNewUser}
                {navItemLogin}
                {navItemClient}
            </ul>
        </>
    )

    const drawer = () => {
        const toggler = (
            <label className="header-drawer-toggler" htmlFor="headerDrawerToggleGuest">
                <span className="header-nav-toggler-guest" aria-hidden="true"/>
            </label>
        )

        const dropdownMenu = (
            <div className="header-nav-dropdown-menu header-nav-drawer-menu header-nav-drawer-menu-guest">
                {navListDrawer}
            </div>
        )

        return (
            <>
                <div className="header-nav-drawer-guest" tabIndex={-1} aria-hidden="true">
                    <input className="header-drawer-toggle" type="checkbox" id="headerDrawerToggleGuest"
                           aria-hidden="true"/>
                    <label className="header-drawer-backdrop" htmlFor="headerDrawerToggleGuest" aria-hidden="true"/>
                    {toggler}
                    <div className="header-drawer-content">
                        <nav>
                            {dropdownMenu}
                        </nav>
                    </div>
                </div>
            </>
        )
    }

    const navItemDrawer = (
        <>
            <div className="header-nav-guest">
                {drawer()}
            </div>
        </>
    )

    const nav = (
        <>
            {navItemDrawer}
            {navListNavbar}
            {navListNavbarLg}
        </>
    )

    return (
        <>
            {nav}
        </>
    )
}
export default HeaderNavbarNav
