import * as React from 'react'

interface HeaderNavItemProps {
  path: string
  text: string
  itemClassName?: string
  linkClassName?: string
  smoothscroll?: boolean
  isModalLink?: boolean
  onClickModalLink?: React.MouseEventHandler<HTMLAnchorElement>
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = props => {
  let itemClassName = 'header-nav-item'
  if (props.itemClassName) {
    itemClassName += ' '
    itemClassName += props.itemClassName
  }

  let linkClassName = 'header-nav-link'
  if (props.linkClassName) {
    linkClassName += ' '
    linkClassName += props.linkClassName
  }

  if (props.smoothscroll) {
    return (
        <>
          <li className={itemClassName}>
            <a
              className={linkClassName}
              href={props.path}
              data-smoothscroll={props.smoothscroll}
              data-turbo={false}
              onClick={e => props.isModalLink ? props.onClickModalLink(e) : undefined}>
              <span className="header-nav-link-text">{props.text}</span>
            </a>
          </li>
        </>
    )
  } else {
    return (
        <>
          <li className={itemClassName}>
            <a
              className={linkClassName}
              href={props.path}
              data-turbo={false}
              onClick={e => props.isModalLink ? props.onClickModalLink(e) : undefined}>
              <span className="header-nav-link-text">{props.text}</span>
            </a>
          </li>
        </>
    )
  }
}

HeaderNavItem.defaultProps = { isModalLink: false }

export default HeaderNavItem
