/* eslint @typescript-eslint/no-var-requires: 1 */

import React, {useEffect, useState} from 'react'
import { referral_path, job_offers_remotes_path, benefit_index_path, knowledge_path } from 'routes'
import LazyImg from 'javascripts/common/LazyImg'

import bannerFriend from 'images/top/banners/friend.png'
import bannerRemote from 'images/top/banners/remote.png'
import bannerBenefit from 'images/top/banners/benefit.png'
import bannerKnowledge from 'images/top/banners/knowledge.png'
import bannerPeacefulMorning from 'images/top/banners/peaceful_morning.png'
import bannerTalentPartners from 'images/top/banners/talent_partners.png'
import bannerCrowdLog from 'images/top/banners/crowdlog.png'
import bannerEmbexEducation from 'images/top/banners/embex_education.png'



const FooterBanner: React.FC<Record<string, never>> = () => {
  const [loggedOut, setLoggedOut] = useState<boolean>(true)
  const [isEngineer, setIsEngineer] = useState<boolean>(false)

  useEffect(() => {
    document.addEventListener('session:fetched', (event: SessionFetchedEvent) => {
      const user = event.detail.session.user
      setLoggedOut(!user)
      if (user) setIsEngineer(user.engineer)
    })
  }, [])

  const EmbexEducationBanner = () => {
    return (
      <li className="banner-item">
        <a className="banner-link" href="/embex_education">
          <LazyImg className="banner-img" src={bannerEmbexEducation} alt="IT技術者研修講師募集中" />
        </a>
      </li>
    )
  }
  return (
    <>
      {!loggedOut && isEngineer && <EmbexEducationBanner/>}
      <li className="banner-item">
        <a className="banner-link" id="ga-footer-bnr-friend" href={referral_path()}>
          <LazyImg className="banner-img" src={bannerFriend} alt="ご友人紹介特典" />
        </a>
      </li>
      <li className="banner-item-l">
        <a className="banner-link" id="ga-footer-bnr-remote" href={job_offers_remotes_path()}>
          <LazyImg className="banner-img" src={bannerRemote} alt="リモートワーク特集" />
        </a>
      </li>
      <li className="banner-item">
        <a className="banner-link" id="ga-footer-bnr-benefit" href={benefit_index_path()}>
          <LazyImg className="banner-img" src={bannerBenefit} alt="会員限定特典" />
        </a>
      </li>
      <li className="banner-item">
        <a className="banner-link" id="ga-footer-bnr-knowledge" href={knowledge_path()}>
          <LazyImg className="banner-img" src={bannerKnowledge} alt="ビギナー向けフリーランスの基礎知識" />
        </a>
      </li>
      <li className="banner-item">
        <a className="banner-link" id="ga-footer-bnr-peaceful-morning" href='https://rpahack.tech/'>
          <LazyImg className="banner-img" src={bannerPeacefulMorning} alt="RPA特化型フリーランスエージェント" />
        </a>
      </li>
      <li className="banner-item">
        <a className="banner-link" id="ga-footer-bnr-talent-partner" href='https://landing.crowdtech.jp/talentpartners'>
          <LazyImg className="banner-img" src={bannerTalentPartners} alt="スタートアップ企業への転職ならTalent Partners" />
        </a>
      </li>
      <li className="banner-item">
        <a className="banner-link" id="ga-footer-bnr-crowdlog" href='https://lp.crowdlog.jp/to-c'>
          <LazyImg className="banner-img" src={bannerCrowdLog} alt="工数管理ならCrowdLog" />
        </a>
      </li>
    </>
  )
}
export default FooterBanner
