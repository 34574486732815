import * as React from 'react'
import * as Routes from 'routes'

const HeaderNavbarNavItemJobOffers: React.FC = () => {
  return (
    <div className="header-nav-job-offers">
      <a className="header-nav-link-job-offers" href={Routes.job_offers_path({ ref: 'from_sp_header_link', campaign: 'from_sp_header_link' })}>
        お仕事検索
      </a>
    </div>
  )
}

export default HeaderNavbarNavItemJobOffers
