import React, { ChangeEvent, useContext } from 'react'
import { IdentifyModalValue } from './StartModal'
import LazyImg from 'javascripts/common/LazyImg'
import CSSModules from 'react-css-modules'
import styles from './ModalForuthView.module.scss'
import ChangeModal from './changeModal'

import Icon from '../../../../../assets/images/identify/icon_fourth.svg'
import ModalButton from 'spa/member/components/ModalButton'

const ModalFourthView: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {
  const context = useContext(IdentifyModalValue)
  const options = [
    { value: 'no', text: '希望しない' },
    { value: 'yes', text: '希望する' },
  ]

  const changeSelectBox = (e: ChangeEvent<HTMLSelectElement>) => {
    const currentValue = e.currentTarget.value
    context.setCompanyNameSelected(currentValue)
    if (currentValue === 'yes') {
      context.setShowCompanyNameInputForm(true)
    } else {
      context.setShowCompanyNameAlertBox(false)
      context.setShowCompanyNameInputForm(false)
      context.setCompanyName('')
      context.setErrors([])
    }
  }

  const changeCompanyName = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value
    context.setCompanyName(currentValue)
    context.setErrors([])
  }

  const applyCorporateName = () => {
    return(
      <>
        <select value={context.companyNameSelected} styleName='select-box' onChange={(event) => changeSelectBox(event)}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </>
    )
  }

  const companyNameForm = () => {
    if (context.showCompanyNameInputForm) {
      return(
          <>
            <div styleName='section-input'>
              <input
                  styleName='input-text'
                  type="text"
                  value={context.companyName}
                  placeholder="法人名を入力してください"
                  onChange={(event) => changeCompanyName(event)}
                  className={context.errors.includes('company_name')? 'input-error' : ''}
              />
              {context.errors.includes('company_name') && <div className='error-message'>旧漢字等、保存できない文字列が含まれてます</div>}
            </div>
          </>
      )
    }
  }

  const renderAlertBox = () => {
    return(
      <>
        {context.showCompanyNameAlertBox && <div styleName='alert-box'>
          <p>法人名が入力されて<br/>いません</p>
          <span onClick={ () => {context.setShowCompanyNameAlertBox( false )} }>×</span>
        </div> }
      </>
    )
  }

  const nextAction = () => {
    if (context.companyNameSelected === 'yes' && context.companyName === '') {
      context.setShowCompanyNameAlertBox(true)
    } else {
      if (context.companyNameSelected === 'no') {
        context.setCompanyName('')
      }
      context.setShowCompanyNameAlertBox(false)
      context.setApplyCompany(context.companyNameSelected)
      props.changeModal('fifthModal')
    }
  }

  const prevAction = () => {
    context.setShowCompanyNameAlertBox(false)
    props.changeModal('thirdModal')
  }

  const cancelAction = () => {
    context.setShowCompanyNameAlertBox(false)
    props.changeModal('')
  }

  return (
    <>
      <div>
        <div styleName='overlay'>
          <div styleName='modal-container' className='js-modal-container'>
            <div styleName='identification-icon'><LazyImg src={Icon} /></div>
            <div styleName='section-title'><p><span>法人名義</span>の希望</p></div>
            <div styleName='section-text'>
              <p>法人名義での申請の希望有無を選択してください。<br/>
              「希望する」を選択した場合、必ず法人名義を入力してください。</p>
            </div>
            <div styleName='section-select'>{applyCorporateName()}</div>
            {companyNameForm()}
            <div styleName='section-btn'>
              <ModalButton buttonType='BACK' onClick={() => prevAction()}>1つ前に戻る</ModalButton>
              <ModalButton buttonType='NEXT' onClick={() => nextAction()}>次へ</ModalButton>
              {renderAlertBox()}
            </div>
              <div styleName='section-btn-close' ><button onClick={() => cancelAction()} styleName='btn-item-close'>キャンセル</button></div>
          </div>
        </div>
      </div>
    </>
  )
}, styles)
export { ModalFourthView }
