import React, { useContext, useEffect } from 'react'
import CSSModules from 'react-css-modules'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './ModalFourthView.module.scss'
import ChangeModal from './changeModal'
import { InvoiceModalValues } from './ModalFirstView'
import { API } from 'javascripts/api'

const ModalFourthView: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {
  const context = useContext(InvoiceModalValues)

  useEffect(() => {
    API.createInvoiceNumber({
      registrated_number: 'T' + context.invoiceNumber
    }).then(response => {
      if (response.status === 200) {
        props.changeModal('fifthModal')
      } else {
        context.setErrors(['api_error'])
        props.changeModal('secondModal')
      }
    }).catch(error => {
      context.setErrors([errorValue(error)])
      props.changeModal('secondModal')
    })
  }, [])

  const errorValue = (error) => {
    if (error.response) {
      const status = error.response.status
      if (status === 400) {
        return 'not_found'
      }
    }
    return 'api_error'
  }

  return (
    <div styleName='overlay'>
      <div styleName='modal-container'>
        <FontAwesomeIcon styleName="loading-icon" icon={['fas', 'spinner']} spinPulse={true} />
        <div styleName='section-text'>
          <p>ただいま登録内容の<br styleName='hidden-sm-up'/>確認を行なっています。</p>
          <p>そのままの状態で<br styleName='hidden-sm-up'/>お待ちください。</p>
        </div>
      </div>
    </div>
  )
}, styles)

export default ModalFourthView
