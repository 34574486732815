import React, { useContext } from 'react'
import { useQRCode } from 'next-qrcode'
import CSSModules from 'react-css-modules'
import styles from './ModalPreparation.module.scss'
import ChangeModal from './changeModal'
import { IdentifyModalValue } from './StartModal'
import ModalButton from 'spa/member/components/ModalButton'

// TODO: 本モーダルを表示するまでの間に受け取った個人データをpropsで受け取る
const ModalPreparation: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {
  const context = useContext(IdentifyModalValue)
  const { SVG } = useQRCode()
  const isPc = !navigator.userAgent.match(/iPhone|Android.+Mobile|iPad/)

  const handleClick = () => window.open(context.shootingUrl, '_blank', 'noreferrer')

  const reload = () => {
    location.reload()
  }

  return (
    <div styleName='overlay'>
      <div styleName='modal-container' className='js-modal-container'>
        <div styleName='preparation_image'></div>
        <div styleName='section-title'><p><span>申請準備</span>が整いました</p></div>
        <div styleName='section-attention'>
          <p>画面を閉じる前に必ず{isPc ? '「QRコードの読み取り」': '申請'}を行なってください。</p>
        </div>
        <div styleName='section-text'>
          <p>本人確認の申請に必要な書類は以下の通りです。</p>
          {isPc && <p>書類をご準備のうえ、以下のQRコードより申請を開始してください。</p>}
          <p>結果は1営業日後に画面上のステータスにて確認できます。</p>
          <p styleName='section-attention small'>住所記載がないパスポートでは、本人確認ができないため他書類をご用意ください</p>
        </div>
        <div styleName='section-callout'>
          <p>{context.requiredDocument}</p>
        </div>
        {isPc && <div styleName='identify-verification-qrcode'>
          <SVG
            text={context.shootingUrl}
            options={{
              margin: 0,
              width: 200,
              color: {
                dark: '#000000',
                light: '#ffffff',
              },
            }}
          />
        </div>}
        <div styleName='section-btn'>
          {isPc ?
            <ModalButton buttonType='NEXT' onClick={reload}>閉じる</ModalButton> :
            <ModalButton buttonType='NEXT' onClick={handleClick}>申請を開始</ModalButton>
          }
        </div>
        {!isPc && <div styleName='section-btn-close'>
          <button onClick={() => props.changeModal('')} styleName='btn-item-close'>キャンセル</button>
        </div>}
      </div>
    </div>
  )
}, styles, { allowMultiple: true })
export default ModalPreparation
