
window.addEventListener('load', () => {
  const scroll = (target:HTMLElement) => {
    const headerHeight = 70
    const rect = target.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const top = rect.top + scrollTop
    const endPosition = Math.max(top - headerHeight, 0)

    // TODO: 以下の動きは liner なので swing っぽくしたい
    const startPosition = document.documentElement.scrollTop || document.body.scrollTop
    const speed = 500
    const begin = new Date().getTime()
    const id = setInterval(() => {
      const current = (new Date()).getTime() - begin
      const goal = (current > speed)
      const ratio = goal ? 1 : (current / speed)
      const newPosition = goal ? endPosition : (startPosition + (endPosition - startPosition) * ratio)
      window.scrollTo(0, newPosition)
      if (goal) clearInterval(id)
    }, 10)
  }

  // 内部リンクのスクロールをスムースにします
  // [data-smoothscroll] 属性が付与されたリンクに対して作用します.
  document.querySelectorAll('[data-smoothscroll]').forEach((dom: HTMLElement) => {
    dom.addEventListener('click', (e) => {
      e.preventDefault()
      const anchor = e.currentTarget as HTMLElement
      const href = anchor.getAttribute('href') || ''
      const target = document.querySelector<HTMLElement>(href == '#' || href == '' ? 'html' : href) || document.querySelector<HTMLElement>('html')
      scroll(target)
      return false
    })
  })

  // window load のタイミングで hash が示す DOM が存在する場合スクロールします
  if (location.hash) {
    window.addEventListener('load', () => {
      const dom: HTMLElement = document.querySelector(location.hash)
      if (dom) {
        setTimeout(() => {
          scroll(dom)
          dom.click()
        }, 300)
      }
      return false
    })
  }
})
