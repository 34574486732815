import React, { useState, createContext } from 'react'
import { ModalFirstView }  from './ModalFirstView'
import { ModalSecondView } from './ModalSecondView'
import { ModalThirdView }  from './ModalThirdView'
import { ModalFourthView } from './ModalFourthView'
import { ModalFifthView }  from './ModalFifthView'
import ModalLoadPreparation from './ModalLoadPreparation'
import ModalPreparation from './ModalPreparation'
import ModalError  from './ModalError'
import LazyImg from 'javascripts/common/LazyImg'
import CSSModules from 'react-css-modules'
import styles from './StartModal.module.scss'

import Icon from '../../../../../assets/images/identify/icon_fixed.svg'
import ModalButton from 'spa/member/components/ModalButton'

export interface profileProps {
  csrf_token: string
  user_id: number
  last_name: string
  first_name: string
  zip: string
  prefecture: string
  address_line_1: string
  address_line_2: string
  overseas: boolean
  company_name: string
  country_name: string
}

export const IdentifyModalValue = createContext({} as {
  lastName: string, setLastName: React.Dispatch<React.SetStateAction<string>>
  firstName: string, setFirstName: React.Dispatch<React.SetStateAction<string>>
  zip: string, setZip: React.Dispatch<React.SetStateAction<string>>
  prefecture: string, setPrefecture: React.Dispatch<React.SetStateAction<string>>
  city: string, setCity: React.Dispatch<React.SetStateAction<string>>
  street: string, setStreet: React.Dispatch<React.SetStateAction<string>>
  buildingName: string, setBuildingName:React.Dispatch<React.SetStateAction<string>>
  addressLine1: string, setAddressLine1:React.Dispatch<React.SetStateAction<string>>
  addressLine2: string, setAddressLine2:React.Dispatch<React.SetStateAction<string>>
  isAddressChanged: boolean, setShowAddressAlertBox: React.Dispatch<React.SetStateAction<boolean>>
  applyCompany: string, setApplyCompany: React.Dispatch<React.SetStateAction<string>>
  showAddressAlertBox: boolean, setIsAddressChanged: React.Dispatch<React.SetStateAction<boolean>>
  overseas: boolean, setOverseas: React.Dispatch<React.SetStateAction<boolean>>
  selectOverseas: boolean, setSelectOverseas: React.Dispatch<React.SetStateAction<boolean>>
  selectNational: string, setSelectNational: React.Dispatch<React.SetStateAction<string>>
  officialDocument: string, setOfficialDocument: React.Dispatch<React.SetStateAction<string>>
  companyName: string, setCompanyName: React.Dispatch<React.SetStateAction<string>>
  companyNameSelected: string, setCompanyNameSelected: React.Dispatch<React.SetStateAction<string>>
  showCompanyNameInputForm: boolean, setShowCompanyNameInputForm: React.Dispatch<React.SetStateAction<boolean>>
  showCompanyNameAlertBox: boolean, setShowCompanyNameAlertBox: React.Dispatch<React.SetStateAction<boolean>>
  country: string, setCountry: React.Dispatch<React.SetStateAction<string>>
  documentPattern: number, setDocumentPattern: React.Dispatch<React.SetStateAction<number>>
  shootingUrl: string, setShootingUrl: React.Dispatch<React.SetStateAction<string>>
  requiredDocument: string, setRequiredDocument: React.Dispatch<React.SetStateAction<string>>
  errors: string[], setErrors: React.Dispatch<React.SetStateAction<string[]>>
})

const IdentifyStartModal: React.FC<profileProps> = CSSModules<React.FC<profileProps>>((props) => {
  
  const [openModal, setOpenedModal] = useState<string>('startModal')
  const [lastName, setLastName] = useState<string>(props.last_name)
  const [firstName, setFirstName] = useState<string>(props.first_name)
  const [zip, setZip] = useState<string>(props.zip)
  const [prefecture, setPrefecture] = useState<string>(props.prefecture)
  const [city, setCity] = useState<string>(props.address_line_1)
  const [street, setStreet] = useState<string>('')
  const [buildingName, setBuildingName] = useState<string>('')
  const [addressLine1, setAddressLine1] = useState<string>(props.address_line_1)
  const [addressLine2, setAddressLine2] = useState<string>(props.address_line_2)
  const [isAddressChanged, setIsAddressChanged] = useState<boolean>(false)
  const [applyCompany, setApplyCompany] = useState<string>('')
  const [showAddressAlertBox, setShowAddressAlertBox] = useState<boolean>(false)
  const [overseas, setOverseas] = useState<boolean>(props.overseas)
  const [selectOverseas, setSelectOverseas] = useState<boolean>(false)
  const [selectNational, setSelectNational] = useState<string>('日本')
  const [officialDocument, setOfficialDocument] = useState<string>('driver-license')
  const [companyName, setCompanyName] = useState<string>(props.company_name ?? '')
  const [companyNameSelected, setCompanyNameSelected] = useState<string>('no')
  const [showCompanyNameInputForm, setShowCompanyNameInputForm] = useState<boolean>(false)
  const [showCompanyNameAlertBox, setShowCompanyNameAlertBox] = useState<boolean>(false)
  const [country, setCountry] = useState<string>(props.country_name)
  const [documentPattern, setDocumentPattern] = useState<number>(1)
  const [shootingUrl, setShootingUrl] = useState<string>('')
  const [requiredDocument, setRequiredDocument] = useState<string>('')
  const [errors, setErrors] = useState<string[]>([])

  const profileUrl = '/profile_sheet/basic_profile'

  const changeModal = (openModal: string) => {
    setOpenedModal(openModal)
  }

  const modalContainer = document.querySelector('.js-modal-container')
  if (!modalContainer) {
    if (openModal === '') {
      setOpenedModal('startModal')
    }
  }

  const renderProfile = () => {
    window.location.href = profileUrl
  }

  const modalStart = () => {
    return(
      <>
        <div styleName='section-title'><p><span>本人確認</span>が必要です</p></div>
        <div styleName='section-text'>
          <p>本人確認には、以下いずれかの書類が必要です。</p>
          <p>「運転免許証」「マイナンバーカード」「パスポート（住所表示があるもの）」「住民票」「在留カード」「特別永住者証明書」</p>
          <p styleName='section-attention small'>住所記載がないパスポートでは、本人確認ができないため他書類をご用意ください</p>
        </div>
        <div styleName='section-btn'>
          <ModalButton buttonType='BACK' onClick={() => changeModal('')}>今はしない</ModalButton>
          <ModalButton buttonType='NEXT' onClick={() => setOpenedModal('firstModal')}>本人確認をする</ModalButton>
        </div>
      </>
    )
  }

  const goToProfileUpdate = () => {
    return(
      <>
        <div styleName='section-title'><p><span>本人確認</span>を申請する前に</p></div>
        <div styleName='section-text'>
          <p>本人確認の申請を行うには、<span>基本情報画面</span>にて先にお住まいの住所をご入力ください。</p>
        </div>
        <div styleName='section-btn'>
          <ModalButton buttonType='BACK' onClick={() => changeModal('')}>あとで</ModalButton>
          <ModalButton buttonType='NEXT' onClick={() => renderProfile()}>今すぐ入力</ModalButton>
        </div>
      </>
      )
  }

  return (
    <>
      <IdentifyModalValue.Provider value={{
        lastName, setLastName,
        firstName, setFirstName,
        zip, setZip,
        prefecture, setPrefecture,
        city, setCity,
        street, setStreet,
        buildingName, setBuildingName,
        addressLine1, setAddressLine1,
        addressLine2, setAddressLine2,
        isAddressChanged, setIsAddressChanged,
        applyCompany, setApplyCompany,
        showAddressAlertBox, setShowAddressAlertBox,
        overseas, setOverseas,
        selectOverseas, setSelectOverseas,
        selectNational, setSelectNational,
        officialDocument, setOfficialDocument,
        companyName, setCompanyName,
        companyNameSelected, setCompanyNameSelected,
        showCompanyNameInputForm, setShowCompanyNameInputForm,
        showCompanyNameAlertBox, setShowCompanyNameAlertBox,
        country, setCountry,
        documentPattern, setDocumentPattern,
        shootingUrl, setShootingUrl,
        requiredDocument, setRequiredDocument,
        errors, setErrors
      }}>
        { openModal == 'firstModal' && <ModalFirstView changeModal = { changeModal } /> }
        { openModal == 'secondModal' && <ModalSecondView changeModal = { changeModal } />}
        { openModal == 'thirdModal' && <ModalThirdView changeModal = { changeModal } />}
        { openModal == 'fourthModal' && <ModalFourthView  changeModal = { changeModal } />}
        { openModal == 'fifthModal' && <ModalFifthView changeModal = { changeModal } profileProps = { props } />}
        { openModal == 'startModal' &&
            <div styleName='overlay'>
                <div styleName='modal-container' className='js-modal-container'>
                    <div styleName='identification-icon'><LazyImg src={Icon} /></div>
                  {props.address_line_1 && modalStart()}
                  {!props.address_line_1 && goToProfileUpdate()}
                </div>
            </div>}
        { openModal == 'loadPreparation' && <ModalLoadPreparation />}
        { openModal == 'preparation' && <ModalPreparation changeModal = { changeModal } />}
        { openModal == 'error' && <ModalError changeModal = { changeModal } error={openModal}/>}
      </IdentifyModalValue.Provider>
    </>
  )
}, styles, { allowMultiple: true })

export default IdentifyStartModal
