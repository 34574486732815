export const formatDate: (dateStr: string) => string = (dateStr) => {
  if (!dateStr) return null

  const date = new Date(dateStr)
  const yyyy = date.getFullYear().toString().padStart(4, '0')
  const mm = (date.getMonth() + 1).toString().padStart(2, '0')
  const dd = date.getDate().toString().padStart(2, '0')

  return `${yyyy}.${mm}.${dd}`
}

export const formatTargetMonth: (dateStr: string) => string = (dateStr) => {
  if (!dateStr) return null

  const date = new Date(dateStr)
  const yyyy = date.getFullYear().toString().padStart(4, '0')
  const mm = (date.getMonth() + 1).toString().padStart(2, '0')

  return `${yyyy}.${mm}`
}

export const formatPercent: (value: number) => string = (value) => {
  return `${Math.round(value * 100)}%`
}

export const formattedZip: (zip: string) => string = (zip) => {
  return zip.slice(0, 3) + '-' + zip.slice(3)
}
