import React, { useEffect, useState } from 'react'
import { useQRCode } from 'next-qrcode'
import CSSModules from 'react-css-modules'
import styles from './ModalPreparation.module.scss'
import ModalError  from './ModalError'
import ChangeModal from './changeModal'
import ModalButton from 'spa/member/components/ModalButton'

type Json = {
  shooting_url: string
  required_document: string
  error: string
}

const ModalStandbyPreparation: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>(() => {
  const { SVG } = useQRCode()
  const [openModal, setOpenModal] = useState<string>('standbyModal')
  const [shootingUrl, setShootingUrl] = useState<string>('/')
  const [requiredDocument, setRequiredDocument] = useState<string>('')
  const isPc = !navigator.userAgent.match(/iPhone|Android.+Mobile|iPad/)
  const [alive, setAlive] = useState(true)

  useEffect(() => {
    showEffect()
    return () => {
      setAlive(false)
    }
  }, [])

  const showEffect = () =>{
    fetch( '/identify_verification', {
      method: 'GET',
    }).then(response => {
      if (response.status === 500) throw new Error
      return response.json()

    }).then((json: Json) => {
      if (!alive) return
      setShootingUrl(json.shooting_url)
      setRequiredDocument(json.required_document)
    }).catch(() => {
      if (!alive) return
      setOpenModal('standbyError')
    })
  }

  //通信エラーモーダルを閉じた後に再度モーダルを開く時にfetch APIの再実行が必要
  const modalPending = document.querySelector('.js-open-identify_verification_modal_pending')
  modalPending.addEventListener('click', () => {
    showEffect()
  })

  const modalContainer = document.querySelector('.js-modal-overlay')
  if (!modalContainer) {
    if (openModal === '') {
      setOpenModal('standbyModal')
    }
  }

  const changeModal = (openModal: string) => {
    setOpenModal( openModal )
  }

  const handleClick = () => window.open(shootingUrl, '_blank', 'noreferrer')

  return (
  <>
    { openModal == 'standbyModal' && <div className='js-modal-overlay' styleName='overlay'>
      <div styleName='modal-container'>
        <div styleName='preparation_image'></div>
        <div styleName='section-title'><p><span>申請準備</span>が整っています</p></div>
        <div styleName='section-text'>
          <p>本人確認の申請に必要な書類は以下の通りです。</p>
          {isPc && <p>書類をご準備のうえ、以下のQRコードより申請を開始してください。</p>}
          <p>結果は1営業日後に画面上のステータスにて確認できます。</p>
        </div>
        <div styleName='section-callout'>
          <p>{requiredDocument}</p>
        </div>
        {isPc && <div styleName='identify-verification-qrcode'>
          <SVG
            text={shootingUrl}
            options={{
              margin: 0,
              width: 200,
              color: {
                dark: '#000000',
                light: '#ffffff',
              },
            }}
          />
        </div>}
        <div styleName='section-btn'>
          {isPc ?
            <ModalButton buttonType='NEXT' onClick={() => changeModal('')}>閉じる</ModalButton> :
            <ModalButton buttonType='NEXT' onClick={handleClick}>申請を開始</ModalButton>
          }
        </div>
        {!isPc && <div styleName='section-btn-close'>
          <button onClick={()=>changeModal('')} styleName='btn-item-close'>キャンセル</button>
        </div>}
      </div>
    </div>}
    {openModal == 'standbyError' && <ModalError changeModal={changeModal} error={openModal}/>}
  </>
  )
}, styles)
export default ModalStandbyPreparation
