import React from 'react'
import CSSModules from 'react-css-modules'
import FlashType from './FlashType'
import styles from './Flash.module.scss'

type FlashCloseHandler = (property: 'success'|'notice'|'alert'|'error') => void;

interface FlashProps {
  flash: FlashType;
  onClose?: FlashCloseHandler;
  additionalClass?: string;
}

const Flash: React.FC<FlashProps> = CSSModules<React.FC<FlashProps>>((props: FlashProps) => {
  const renderInternal = (property: 'success'|'notice'|'alert'|'error', className: string) => {
    const message = props.flash[property]
    if (!message) return null

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()

      event.currentTarget.closest('.show')?.classList.remove('show')
    }

    const handleTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (props.onClose) {
        props.onClose(property)
      }
    }

    return (
      <div
        className={`alert alert-dismissible fade show flash-alert ${className}`}
        role="alert"
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="container">
          <p className="alert-text" dangerouslySetInnerHTML={{ __html: message }} />
          <p className="alert-button">
            <button type="button" data-dismiss="alert" aria-label="Close" onClick={handleClose}>&times;</button>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div styleName={`flash-container ${props.additionalClass ?? ''}`}>
      { renderInternal('success', '-success') }
      { renderInternal('notice', '-success') }
      { renderInternal('alert', '-error') }
      { renderInternal('error', '-error') }
    </div>
  )
}, styles,{ allowMultiple: true })


export { Flash, FlashProps, FlashCloseHandler }
