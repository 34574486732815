import * as React from 'react'
import * as Routes from 'routes'
import User from './User'
import HeaderNavbarNavItemWorkReport from './HeaderNavbarNavItemWorkReport'
import HeaderNavItem from './HeaderNavItem'
import HeaderNavbarNavItemJobOffers from './HeaderNavbarNavItemJobOffers'

interface HeaderNavBarNavForUserProps {
  user: User;
}

const HeaderNavBarNavForUser: React.FC<HeaderNavBarNavForUserProps> = props => {
  const user = props.user

  const navItemDashboard = (
    <HeaderNavItem path={Routes.dashboard_path()} text='ダッシュボード' />
  )

  const navItemJobOffers = (
    <HeaderNavItem path={Routes.job_offers_path()} text='募集案件' />
  )

  const interviewLinkList = () => {
    if (user.projects && user.projects.length) return null

    if (user.screening_survey_target) {
      return (
        <ul className="header-nav-dropdown-list header-nav-dropdown-list-user">
          <HeaderNavItem
            path={Routes.questionnaire_path()} text='希望条件アンケート回答のお願い'
            itemClassName='header-nav-link-questionnaire'
          />
        </ul>
      )
    } else if (user.interview_required) {
      return (
        <ul className="header-nav-dropdown-list header-nav-dropdown-list-user">
          <HeaderNavItem path={Routes.interview_request_path()} text='面談設定' />
        </ul>
      )
    }
  }
  const clickModalLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    document.cookie = 'dashboard_identify_modal_open=true;path=/;max-age=60'
    return window.location.href = '/dashboard'
  }
  if (location.pathname === '/dashboard') {
    const r = document.cookie.split(';')
    r.forEach(function(value) {
      const content = value.split('=')
      const key = content[0].trim()
      if (key === 'dashboard_identify_modal_open') {
        document.cookie = 'dashboard_identify_modal_open=true;path=/;max-age=0'
        const identifyVerificationModalStatus = document.getElementById('identify_verification_modal_status')
        identifyVerificationModalStatus.setAttribute('value', 'open')
      }
    })
  }
  
    const identifyRemindLink = () => {
    if (props.user.identify_verification_required) {
      return (
        <ul className="header-nav-dropdown-list header-nav-dropdown-list-user hidden-xs">
          <HeaderNavItem
            path=''
            text='本人確認のお願い'
            isModalLink={true}
            onClickModalLink={clickModalLink}
            itemClassName='header-nav-link-questionnaire'
          />
        </ul>
      )
    }
  }

  const clickInvoiceRegisterModalLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    document.cookie = 'profile_sheet_invoice_input_modal_open=true;path=/;max-age=60'
    return window.location.href = Routes.profile_sheet_basic_profile_path()
  }
  if (location.pathname === Routes.profile_sheet_basic_profile_path()) {
    const r = document.cookie.split(';')
    r.forEach(function(value) {
      const content = value.split('=')
      const key = content[0].trim()
      // インボイス確認モーダルを開く
      if (key === 'profile_sheet_invoice_modal_open') {
        document.cookie = 'profile_sheet_invoice_modal_open=true;path=/;max-age=0'
        const invoiceCheckModalStatus = document.getElementById('invoice_check_modal_status')
        invoiceCheckModalStatus.setAttribute('value', 'open')
      }
      // インボイス入力モーダルを開く
      if (key === 'profile_sheet_invoice_input_modal_open') {
        document.cookie = 'profile_sheet_invoice_input_modal_open=true;path=/;max-age=0'
        const invoiceCheckModalStatus = document.getElementById('invoice_check_modal_status')
        invoiceCheckModalStatus.setAttribute('value', 'input_modal_open')
      }
    })
  }

  const invoiceRegisterLink = () => {
    if (props.user.show_invoice_request_on_profile_and_menu) {
      return (
        <ul className="header-nav-dropdown-list header-nav-dropdown-list-user">
          <HeaderNavItem
            path=''
            text='インボイス登録番号の入力'
            isModalLink={true}
            onClickModalLink={clickInvoiceRegisterModalLink}
            itemClassName='header-nav-link-questionnaire'
          />
        </ul>
      )
    }
  }

  const forWorkingUserItem = () => {
    if (!user.has_published_projects) return null

    return (
      <>
        <h6 className="header-nav-dropdown-header">
          契約関連
        </h6>
        <ul className="header-nav-dropdown-list header-nav-dropdown-list-user">
          <HeaderNavItem path={Routes.projects_path()} text='契約一覧' />
          <HeaderNavItem path={Routes.payments_path()} text='お支払い一覧' />
          <HeaderNavItem path={Routes.bank_account_path()} text='振込先銀行口座の登録' />
          <HeaderNavItem path={Routes.contact_path()} text='本人情報・緊急連絡先' />
        </ul>
      </>
    )
  }
  const linkLists = (
    <>
      {forWorkingUserItem()}
      <h6 className="header-nav-dropdown-header">
        お仕事探し便利機能
      </h6>
      <ul className="header-nav-dropdown-list header-nav-dropdown-list-user">
        <HeaderNavItem path={Routes.favorite_job_offers_path()} text='気になったお仕事' />
        <HeaderNavItem path={Routes.job_offers_application_histories_path()} text='応募履歴' />
        <HeaderNavItem path={Routes.searching_bookmarks_path()} text='保存した条件' />
      </ul>
      <h6 className="header-nav-dropdown-header">
        プロフィール設定
      </h6>
      <ul className="header-nav-dropdown-list header-nav-dropdown-list-user">
        <HeaderNavItem path={Routes.profile_sheet_path()} text='プロフィールシート' />
        <HeaderNavItem path={Routes.account_path()} text='アカウント設定' />
      </ul>
      <ul className="header-nav-dropdown-list header-nav-dropdown-list-user">
        <HeaderNavItem path={Routes.faq_path()} text='よくある質問' />
        <HeaderNavItem path={Routes.inquiry_path()} text='お問い合わせ' />
        <HeaderNavItem path={Routes.logout_path()} text='ログアウト' />
      </ul>
    </>
  )

  const navListNavbar = (
    <>
      <ul className="header-navbar-nav-list header-navbar-nav-list-sm">
        {navItemDashboard}
        {navItemJobOffers}
      </ul>
    </>
  )

  const navListDrawer = (
    <>
      {interviewLinkList()}
      <ul className="header-nav-dropdown-list header-nav-dropdown-list-user-sp">
        {navItemDashboard}
        {navItemJobOffers}
      </ul>
      {linkLists}
    </>
  )

  const navListDropdown = (
    <>
      {interviewLinkList()}
      {identifyRemindLink()}
      {invoiceRegisterLink()}
      {linkLists}
    </>
  )

  const drawer = () => {
    const toggler = (
      <label className="header-drawer-toggler" htmlFor="headerDrawerToggleUser">
        <span className="header-nav-toggler-user" aria-hidden="true" />
      </label>
    )

    const dropdownMenu = (
      <div className="header-nav-dropdown-menu header-nav-drawer-menu">
        {navListDrawer}
      </div>
    )

    return (
      <>
        <div className="header-nav-drawer-user" tabIndex={-1} aria-hidden="true">
          <input className="header-drawer-toggle" type="checkbox" id="headerDrawerToggleUser" aria-hidden="true" />
          <label className="header-drawer-backdrop" htmlFor="headerDrawerToggleUser" aria-hidden="true" />
          {toggler}
          <div className="header-drawer-content">
            <nav>
              {dropdownMenu}
            </nav>
          </div>
        </div>
      </>
    )
  }

  const dropdown = () => {
    const toggler = (
      <button
        type="button" className="header-dropdown-toggler" id="dropdownMenuUser"
        data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false"
      >
        <span className="header-nav-toggler-user" aria-hidden="true" />
      </button>
    )

    const dropdownMenu = (
      <div className="header-nav-dropdown-menu header-nav-dropdown-menu-scroll">
        {navListDropdown}
      </div>
    )

    return (
      <>
        <div className="header-nav-dropdown-user">
          {toggler}
          <div className="dropdown-menu header-dropdown-menu">
            {dropdownMenu}
          </div>
        </div>
      </>
    )
  }

  const navItemUser = (
    <>
      <div className="header-nav-user">
        {drawer()}
        {dropdown()}
      </div>
    </>
  )

  const nav = (
    <>
      {navItemUser}
      { !user.projects.length && <HeaderNavbarNavItemJobOffers /> }
      <HeaderNavbarNavItemWorkReport projects={user.projects} />
      {navListNavbar}
    </>
  )

  return (
    <>
      {nav}
    </>
  )
}

export default HeaderNavBarNavForUser
