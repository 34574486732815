import React, { useContext } from 'react'
import CSSModules from 'react-css-modules'
import styles from './ModalThirdView.module.scss'
import { InvoiceModalValues } from './ModalFirstView'
import ModalButton from 'spa/member/components/ModalButton'
import ChangeModal from './changeModal'

interface ThirdModalProps extends ChangeModal {
  name: string,
}

const ModalThirdView: React.FC<ThirdModalProps> = CSSModules<React.FC<ThirdModalProps>>((props) => {
  const context = useContext(InvoiceModalValues)
  const onClickBack = () => {
    context.setErrors([])
    props.changeModal('secondModal')
  }
  return (
    <div styleName='overlay'>
      <div styleName='modal-container' className='js-modal-container'>
        <div styleName='section-title'><p>内容をご確認ください。</p></div>
        <div styleName='section-divider'></div>
        <div styleName='section-contents'>
          <div styleName='label'>適格請求書発行事業者 氏名又は名称</div>
          <div styleName='value'>{props.name}</div>
          <div styleName='label'>インボイス登録番号</div>
          <div styleName='value'>T{context.invoiceNumber}</div>
        </div>
        <div styleName='section-text'>国税庁へ届け出ている内容と同一であるか照合を行い、<br/>確認が取れた場合のみ入力を完了します。</div>
        <div styleName='section-btn'>
          <ModalButton buttonType='BACK' onClick={() => onClickBack()}>1つ前に戻る</ModalButton>
          <ModalButton buttonType='NEXT' onClick={() => props.changeModal('fourthModal')} className='third-modal-go-next-btn'>照合し入力を完了する</ModalButton>
        </div>
      </div>
    </div>
  )
}, styles)

export { ModalThirdView }
