import React, { useContext, useEffect } from 'react'
import LazyImg             from 'javascripts/common/LazyImg'
import CSSModules          from 'react-css-modules'
import ChangeModal from './changeModal'
import styles              from './ModalSecondView.module.scss'
import JapanPostalCode     from 'japan-postal-code'
import { IdentifyModalValue }    from './StartModal'

import Icon from '../../../../../assets/images/identify/icon_second.svg'
import ModalButton from 'spa/member/components/ModalButton'

const ModalSecondView: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {
  const context = useContext(IdentifyModalValue)
  // DS社側のAPIの受取が、市区町村と番地で別れているため、分離するための処理が必要です。
  const concatAddress = `${ context.addressLine1 }${ context.addressLine2 }`
  const buildingName = context.addressLine2

  useEffect(() => {
    if (!context.isAddressChanged) {
      !context.overseas ? JapanPostalCode.get(context.zip, address => {
        context.setCity(address.city)
        context.setBuildingName(context.addressLine2)
        const regex = new RegExp(`${address.city}|${buildingName}`,'g')
        const areaStreet = concatAddress.replace(regex, '')
        context.setStreet(areaStreet)
      }) : context.setCity(context.addressLine1)
    }
  }, [])

  const isProfile = () => {
    return [ context.lastName, context.firstName, context.zip, context.prefecture, context.city, context.street ].every( Boolean ) && /^\d{7}$/.test(context.zip)
  }

  const isOverseas = () => {
    return [ context.lastName, context.firstName, context.country, context.city ].every( Boolean )
  }

  const nextAction = () => {
    if ( isProfile() && !context.overseas || isOverseas() && context.overseas ) {
      context.setShowAddressAlertBox(false)
      props.changeModal('thirdModal')
    } else {
      context.setShowAddressAlertBox(true)
    }
  }

  const prevAction = () => {
    context.setShowAddressAlertBox(false)
    props.changeModal('firstModal')
  }

  const cancelAction = () => {
    context.setShowAddressAlertBox(false)
    props.changeModal('')
  }

  const displayFullName = () => {
    return(
      <div styleName='place-item'>
        <p styleName='text-key'>お名前 ：<span>{context.lastName}&nbsp;{context.firstName}</span></p>
      </div>
    )
  }

  const displayAddress = () => {
    if (context.zip !== '' && context.prefecture !== '') {
      return(
        <div styleName='place-item'>
          <p styleName='text-key'>ご住所 ：</p>
          <div styleName='address-value'>
            <p>〒{context.zip}&nbsp;</p><p>{context.prefecture} {context.city}{context.street}&nbsp;</p><p>{context.buildingName}</p>
          </div>
        </div>
      )
    } else {
      return(
        <div styleName='place-item'>
          <p styleName='text-key'>ご住所 ：</p>
          <div styleName='address-value'>
            {context.country}&nbsp;{context.city}
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div>
        <div styleName='overlay'>
          <div styleName='modal-container' className='js-modal-container'>
            <div styleName='identification-icon'><LazyImg src={Icon} /></div>
            <div styleName='section-title'><p><span>お名前・ご住所</span>の確認</p></div>
            <div styleName='section-text'>
              <p>選択した公的書類に記載されているお名前・ご住所に相違はありませんか？<br />
                相違がある場合には、<a href="/profile_sheet/basic_profile">基本情報ページ</a>から正確な内容を入力してください。</p>
            </div>
            {displayFullName()}
            {displayAddress()}
            <div styleName='section-btn'>
              <ModalButton buttonType='BACK' onClick={() => prevAction()}>1つ前に戻る</ModalButton>
              <ModalButton buttonType='NEXT' onClick={()=> nextAction()}>次へ</ModalButton>
            </div>
            <div styleName='section-btn-close' ><button onClick={() => cancelAction()} styleName='btn-item-close'>キャンセル</button></div>
          </div>
        </div>
      </div>
    </>
  )
}, styles)
export { ModalSecondView }
