import React, { useContext, useEffect, useState }  from 'react'
import { profileProps, IdentifyModalValue } from './StartModal'
import LazyImg              from 'javascripts/common/LazyImg'
import CSSModules           from 'react-css-modules'
import styles               from './ModalFifthView.module.scss'
import ChangeModal from './changeModal'

import Icon from '../../../../../assets/images/identify/icon_fifth.svg'
import ModalButton from 'spa/member/components/ModalButton'
import { formattedZip } from 'javascripts/common/format'

interface fifthModalProps extends ChangeModal{
  profileProps?: profileProps
}

type Json = {
  shooting_url: string
  required_document: string
  error: string
  company_name: string[]
}

const ModalFifthView: React.FC<fifthModalProps> = CSSModules<React.FC<fifthModalProps>>((props) => {
  const context = useContext(IdentifyModalValue)
  const [alive, setAlive] = useState(true)

  const contactData = {
    authenticity_token: props.profileProps.csrf_token,
    user_id: props.profileProps.user_id,
    last_name: context.lastName,
    first_name: context.firstName,
    overseas: context.selectOverseas,
    zip:  context.zip,
    prefecture_id: context.country || context.prefecture,
    city_name: context.city,
    address_line_1: context.street,
    address_line_2: context.buildingName,
    company_name: context.companyName,
    document_pattern: context.documentPattern,
  }


  const unitAddress = () => {
    if (context.zip !== '' && context.prefecture !== '') {
      return(
        <div styleName='address-value'>
          <p>〒{formattedZip(context.zip)}&nbsp;</p><p>{context.prefecture} {context.city}{context.street}&nbsp;</p><p>{context.buildingName}</p>
        </div>
      )
    } else {
      return(
        <>
          {context.country}&nbsp; {context.city}
        </>
      )
    }
  }

  const genApplyCompany = () => {
    if(context.applyCompany == 'no'){
      return '希望しない'
    } else {
      return '希望する'
    }
  }

  const isOverseas = () => {
    return context.selectOverseas? '日本以外':'日本'
  }

  const validateKeys = ['last_name','first_name','city_name','address_line_1','address_line_2']

  const validateChecks = (validateKeys: string[]) => (json: Json) =>
    validateKeys.some(key => json[key] && json[key].length > 0)

    useEffect(() => {
      return () => setAlive(false)
    }, [])

  const handleSubmit = () => {
    props.changeModal('loadPreparation')
    fetch( '/identify_verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( contactData )
    } )
      .then(response => {
        return response.json()
      })
      .then((json: Json) => {
        if (!alive) return
        if (json.error == 'api_error') { throw new Error(json.error) }
        if (validateChecks(validateKeys)(json)) {
          context.setErrors(Object.keys(json))
          props.changeModal('secondModal')
          return
        } else if(json.company_name){
          context.setErrors(Object.keys(json))
          props.changeModal('fourthModal')
          return
        } else {
          context.setShootingUrl(json.shooting_url)
          context.setRequiredDocument(json.required_document)
          props.changeModal('preparation')
        }
      })
      .catch( () => {
        if (!alive) return
        props.changeModal('error')
    })
  }

  const companyName = () => {
    if(context.applyCompany == 'no'){
      return ''
    } else {
      return context.companyName
    }
  }

  return (
    <>
      <div>
        <div styleName='overlay'>
          <div styleName='modal-container' className='js-modal-container'>
            <div styleName='identification-icon'><LazyImg src={Icon} /></div>
            <div styleName='section-title'><p><span>入力情報</span>の確認</p></div>
            <div styleName='section-text'>
              <p>以下の情報で間違いありませんか？</p>
            </div>
            <div styleName='section-value'>
              <div styleName='place-item'>
                <p styleName='text-key'>住所 ： </p>
                {unitAddress()}
              </div>
              <div styleName='value-box'>
                <div styleName='national-item'><p styleName='text-key'>国籍 ： </p>{isOverseas()}</div>
                <div styleName='company-item'>
                  <div styleName='text-list'><p styleName='text-key'>法人申請名義 ： </p>{genApplyCompany()}</div>
                  {companyName()}
                </div>
              </div>
            </div>
            <div styleName='section-btn'>
              <ModalButton buttonType='BACK' onClick={() => props.changeModal('firstModal')}>修正する</ModalButton>
              <ModalButton buttonType='NEXT' onClick={handleSubmit}>確認</ModalButton>
            </div>
            <div styleName='section-btn-close' ><button onClick={() => props.changeModal('')} styleName='btn-item-close'>キャンセル</button></div>
          </div>
        </div>
      </div>
    </>
  )
}, styles)
export { ModalFifthView }
