import React from 'react'
import CSSModules from 'react-css-modules'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './ModalLoadPreparation.module.scss'

const ModalLoadPreparation: React.FC = CSSModules<React.FC>(() => {
  return (
    <div styleName='overlay'>
      <div styleName='modal-container'>
        <FontAwesomeIcon styleName="loading-icon" icon={['fas', 'spinner']} spinPulse={true} />
        <div styleName='section-text'>
          <p>ただいま申請準備を行なっています。</p>
          <p>そのままの状態でお待ちください。</p>
        </div>
      </div>
    </div>
  )
}, styles)
export default ModalLoadPreparation
