import React from 'react'
import LazyImg from 'javascripts/common/LazyImg'
import CSSModules from 'react-css-modules'
import styles from './ModalError.module.scss'
import ChangeModal from './changeModal'

import Icon from '../../../../../assets/images/identify/icon_error.svg'
import ModalButton from 'spa/member/components/ModalButton'

interface ErrorModalProps extends ChangeModal{
  error: string
}

const ModalError: React.FC<ErrorModalProps> = CSSModules<React.FC<ErrorModalProps>>((props) => {

  return (
    <>
      <div styleName='overlay' className='js-modal-overlay'>
        <div styleName='modal-container' className='js-modal-container'>
          <div styleName='identification-icon'><LazyImg src={Icon} /></div>
          <div styleName='section-title'><p>通信<span>エラー</span>が発生しました</p></div>
          <div styleName='section-text'>
            <p>大変申し訳ありませんが、時間を空けて再度申請を行なって頂きます様、よろしくお願いいたします。</p>
          </div>
          <div styleName='section-btn'>
            <ModalButton buttonType='BACK' onClick={() => props.changeModal('')}>閉じる</ModalButton>
          </div>
        </div>
      </div>
    </>
  )
}, styles)
export default ModalError
