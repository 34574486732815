import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './ModalNoNumberView.module.scss'
import ChangeModal from './changeModal'
import Icon from '../../../../../assets/images/invoice/icon_fifth.svg'
import ModalButton from 'spa/member/components/ModalButton'

const NoNumberView: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {

  return (
    <div styleName='overlay' className='js-modal-overlay'>
      <div styleName='modal-container' className='js-modal-container'>
        <div styleName='invoice-icon'><img src={Icon} /></div>
        <div styleName='section-title'><p>ご回答ありがとうございます。</p></div>
        <div styleName='section-text'>
          <p>インボイス登録番号はマイページメニューより登録できます。ご回答ありがとうございました。</p>
        </div>
        <div styleName='section-btn-close'>
          <ModalButton buttonType='NEXT' onClick={() => props.changeModal('')}>閉じる</ModalButton>
        </div>
      </div>
    </div>
  )
}, styles)

export default NoNumberView
