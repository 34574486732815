import React, { ChangeEvent, useContext } from 'react'
import { IdentifyModalValue }          from './StartModal'
import LazyImg                from 'javascripts/common/LazyImg'
import CSSModules             from 'react-css-modules'
import styles                 from './ModalThirdView.module.scss'
import ChangeModal from './changeModal'

import Icon from '../../../../../assets/images/identify/icon_third.svg'
import ModalButton from 'spa/member/components/ModalButton'

const ModalThirdView: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {
  const context = useContext(IdentifyModalValue)
  const options = [
    { value: 'false', text: '日本' },
    { value: 'true', text: '日本以外' },
  ]

  const setItem = (event: ChangeEvent<HTMLSelectElement>) => {
    context.setSelectNational(event.target.value.toLowerCase())
    if (event.target.value.toLowerCase() === 'true') {
      context.setSelectOverseas(true)
    } else {
      context.setSelectOverseas(false)
    }
  }

  const selectNationality = () => {
    return(
        <>
          <select value={context.selectNational} onChange={event => setItem(event)} styleName='select-box'>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>))}
          </select>
        </>
    )
  }

  const nextModal = () => {
    props.changeModal('fourthModal')
  }

  return (
    <>
      <div>
        <div styleName='overlay'>
          <div styleName='modal-container' className='js-modal-container'>
            <div styleName='identification-icon'><LazyImg src={Icon} /></div>
            <div styleName='section-title'><p><span>国籍</span>の選択</p></div>
            <div styleName='section-text'>
              <p>あなたの国籍を選択してください。</p>
            </div>
            <div styleName='section-select'>{selectNationality()}</div>
            <div styleName='section-btn'>
              <ModalButton buttonType='BACK' onClick={() => props.changeModal('secondModal')}>1つ前に戻る</ModalButton>
              <ModalButton buttonType='NEXT' onClick={() => nextModal()}>次へ</ModalButton>
            </div>
            <div styleName='section-btn-close' ><button onClick={() => props.changeModal('')} styleName='btn-item-close'>キャンセル</button></div>
          </div>
        </div>
      </div>
    </>
  )
}, styles)
export { ModalThirdView }
