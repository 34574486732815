import React from 'react'
import PropTypes from 'prop-types'

import lozad from 'lozad'

export default class LazyImg extends React.Component {
  componentDidMount() {
    const observer = lozad(this.img)
    observer.observe()
  }

  render() {
    const { className, alt, src } = this.props
    return (
      <img ref={(el) => this.img = el} className={className} alt={alt} data-src={src}
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      />
    )
  }
}

LazyImg.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
}

LazyImg.defaultProps = {
  alt: '',
  className: '',
}
