import React, { ChangeEvent, useContext } from 'react'
import LazyImg from 'javascripts/common/LazyImg'
import CSSModules from 'react-css-modules'
import styles from './ModalFirstView.module.scss'
import ChangeModal from './changeModal'

import Icon from '../../../../../assets/images/identify/icon_first.svg'
import { IdentifyModalValue } from './StartModal'
import ModalButton from 'spa/member/components/ModalButton'

const ModalFirstView: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {
  const context = useContext(IdentifyModalValue)
  const options = [
    { value: '運転免許証' },
    { value: 'マイナンバーカード' },
    { value: 'パスポート（住所表示があるもの）' },
    { value: '在留カード' },
    { value: '特別永住者証明書' },
    { value: '住民票' },
  ]

  const changeSelectBox = (e: ChangeEvent<HTMLSelectElement>) => {
    const currentIndex = e.currentTarget.selectedIndex
    context.setOfficialDocument(options[currentIndex].value)
    context.setDocumentPattern(currentIndex + 1)
  }

  const selectOfficialDocument = () => {
    return(
      <>
        <select value={context.officialDocument} onChange={e => changeSelectBox(e)} styleName='select-box'>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      </>
    )
  }

  return (
    <>
      <div styleName='overlay'>
        <div styleName='modal-container' className='js-modal-container'>
          <div styleName='identification-icon'><LazyImg src={Icon} /></div>
          <div styleName='section-title'><p><span>公的書類</span>の選択</p></div>
          <div styleName='section-text'>
            <p>ご準備が可能な公的書類を選択してください</p>
            <p styleName='section-attention small'>住所記載がないパスポートでは、本人確認ができないため他書類をご用意ください</p>
          </div>
          <div styleName='section-select'>{selectOfficialDocument()}</div>
          <div styleName='section-btn'>
            <ModalButton buttonType='BACK' onClick={()=> props.changeModal('startModal')}>1つ前に戻る</ModalButton>
            <ModalButton buttonType='NEXT' onClick={()=> props.changeModal('secondModal')}>次へ</ModalButton>
          </div>
          <div styleName='section-btn-close' ><button onClick={() => props.changeModal('')} styleName='btn-item-close'>キャンセル</button></div>
        </div>
      </div>
    </>
  )
}, styles, { allowMultiple: true })
export { ModalFirstView }
