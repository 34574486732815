import React from 'react'
import { profile_sheet_basic_profile_path } from 'routes'
import CSSModules from 'react-css-modules'
import styles from './ModalFifthView.module.scss'
import ChangeModal from './changeModal'
import Icon from '../../../../../assets/images/invoice/icon_fifth.svg'
import ModalButton from 'spa/member/components/ModalButton'

const ModalFifthView: React.FC<ChangeModal> = CSSModules<React.FC<ChangeModal>>((props) => {
  const reload = () => {
    location.reload()
  }
  return (
    <div styleName='overlay' className='js-modal-overlay'>
      <div styleName='modal-container' className='js-modal-container'>
        <div styleName='invoice-icon'><img src={Icon} /></div>
        <div styleName='section-title'><p>ご入力ありがとうございます。</p></div>
        <div styleName='section-text'>
          <p styleName='thankyou-text'>ご入力ありがとうございました。内容に変更がある場合は、
            <a styleName='profile-link-text' href={profile_sheet_basic_profile_path()}>
              基本情報ページ
            </a>
            から変更してください。
          </p>
        </div>
        <div styleName='section-btn'>
          <ModalButton buttonType='NEXT' onClick={reload}>閉じる</ModalButton>
        </div>
      </div>
    </div>
  )
}, styles)

export default ModalFifthView
