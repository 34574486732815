declare const window: Window['window'] & {
  dataLayer: object[]
  jQuery: JQueryStatic
  $: JQueryStatic
}

window.jQuery = window.$ = $
import 'bootstrap/dist/js/bootstrap'

import 'jquery-ui/dist/jquery-ui'
import 'jquery-ui/ui/widgets/autocomplete'
import 'jquery-ui/themes/base/theme.css'
import 'jquery-ui/themes/base/autocomplete.css'
import 'time-to/timeTo'


import './smoothscroll'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Routes from 'routes'

import SessionFetchedEvent from './SessionFetchedEvent'
import { FlashProps } from './Flash'

import FooterRegister from './FooterRegister'
import Header from './Header'
import FooterBanner from './FooterBanner'
import IdentifyStartModal, { profileProps } from './identify_verification/StartModal'
import ModalStandbyPreparation from './identify_verification/ModalStandbyPreparation'
import InvoiceStartModal, { InvoiceProps } from './invoive_check/ModalFirstView'

window.addEventListener('DOMContentLoaded', () => {

  // ページが読み込まれれた時に、改行コード分のrowsを設定
  $('textarea').each(function() {
    const lines = $(this).val().toString()
    const linesCount = (lines + '\n').match(/\n/g).length
    $(this).attr('rows', (linesCount > 5) ? linesCount : 5)
  })

  // TB幅以下のフッターメニューを開閉用
  const windowSm = 991
  let windowWidth = $(window).width()

  // TB幅以下のフッターメニューを開閉用のリサイズされた場合
  $(window).on('load resize', function() {
    // ブラウザの横幅をリサイズ時に変更
    windowWidth = $(window).width()

    // リサイズ時タブレット幅以上の場合は、フルオープンで該当クラスも外す
    if (windowWidth >= windowSm) {
      $('.js-footer-toggle').addClass('active')
      $('.js-footer-toggle').next().stop().fadeIn(0)
    } else {
      if ($('.js-footer-toggle').parents('.error').length <= 0) {
        $('.js-footer-toggle').removeClass('active')
        $('.js-footer-toggle').next().stop().fadeOut(0)
      }
    }
  })

  // TB幅以下の場合、フッターメニューを開閉に
  // errorページは開閉しない

  $('.js-footer-toggle').on('click', (e) => {
    if ((windowWidth <= windowSm) && ($('.js-footer-toggle').parents('.error').length <= 0)) {
      const $bigcate = $(e.target)
      const $item = $bigcate.next()
      $bigcate.toggleClass('active')
      $item.stop().fadeToggle()
    }
  })

  // SPで表示されるナビゲーション内グラフのクローズボタン処理
  $('#graph-close-sp').on('click', () => {
    $('#graph-line-menu-placeholder .graph-describe').hide(0)
    $('#graph-line-menu-placeholder .graph-line').removeClass('-old')
    $('#graph-line-menu-placeholder .graph-line-span').removeClass('-old')
    $('#graph-line-menu-placeholder .graph-line-span-small').removeClass('-old')
  })
})

$(function() {
  $(document).on('click', '.toggle-btn', (e) => { $(e.target).toggleClass('is-active') })
  $(document).on('change', 'select.js-url-select', (event) => { window.location.href = $(event.target).find('option:checked').data('url') as string})
})

window.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('session:fetched', (event: SessionFetchedEvent) => {
    // CSRF token
    if (event.detail.session.csrf_token) {
      const csrfParam = document.createElement('meta')
      csrfParam.name = 'csrf-param'
      csrfParam.content = event.detail.session.csrf_param
      document.head.appendChild(csrfParam)

      const csrfToken = document.createElement('meta')
      csrfToken.name = 'csrf-token'
      csrfToken.content = event.detail.session.csrf_token
      document.head.appendChild(csrfToken)
    }

    const user = event.detail.session.user
    if (!user) return

    // GTMにuser_idを送るためにdataLayerに値を格納
    if (typeof(window.dataLayer) !== 'undefined') {
      window.dataLayer.push({ 'uid': user.id })
    }

    if (document.querySelector('body.controller--top')) {
      location.href = Routes.dashboard_path()
      return
    }

    document.body.classList.add('logged_in')

    document.querySelectorAll('[data-react-footer-register]').forEach(footerRegisterPlaceholder => {
      ReactDOM.render(<FooterRegister />, footerRegisterPlaceholder)
    })
  })

  const headerPlaceholder: HTMLElement = document.querySelector('[data-react-header]')
  if (headerPlaceholder) {
    const props: FlashProps = JSON.parse(headerPlaceholder.dataset.reactHeader) as FlashProps
    ReactDOM.render(<Header flash={props.flash} />, headerPlaceholder)
  }

  const footerBannerPlaceholder = document.querySelector('[data-react-footer-banner]')
  if (footerBannerPlaceholder) {
    ReactDOM.render(<FooterBanner />, footerBannerPlaceholder)
  }

  // 変更を監視するノードを選択
  const identifyVerificationModalStatus = document.getElementById('identify_verification_modal_status')

  // (変更を監視する) オブザーバーのオプション
  const config = { attributes: true, childList: false, subtree: false }

  const callback = function() {
    if (identifyVerificationModalStatus.getAttribute('value') === '') {
      return
    }
    openIdentifyModal()
    identifyVerificationModalStatus.setAttribute('value', '')
  }

  const observer = new MutationObserver(callback)
  if (identifyVerificationModalStatus) {
    observer.observe(identifyVerificationModalStatus, config)
  }

  const identifyModalOpenner = document.querySelector('.js-open-identify_verification_modal')
  if (identifyModalOpenner) {
    identifyModalOpenner.addEventListener('click', (event) => {
      event.preventDefault()
      const identifyVerificationModalStatus = document.getElementById('identify_verification_modal_status')
      identifyVerificationModalStatus.setAttribute('value', 'open')
    })
  }

  const modalPending = document.querySelector('.js-open-identify_verification_modal_pending')
  if (modalPending) {
    modalPending.addEventListener('click', (event) => {
      event.preventDefault()
      const modalOverlay: HTMLElement = document.querySelector('.js-modal-overlay')
      if (modalOverlay) return modalOverlay.hidden = false
      const identifyModal: HTMLElement = document.querySelector('#js-identify-modal')
      ReactDOM.render(<ModalStandbyPreparation />, identifyModal)
    })
  }

  const profileSheetIdentifyModalOpener = document.querySelector('.identify-button.identify-button-none.identify-profile-sheet-button')
  if (profileSheetIdentifyModalOpener) {
    profileSheetIdentifyModalOpener.addEventListener('click', (event) => {
      event.preventDefault()
      const identifyVerificationModalStatus = document.getElementById('identify_verification_modal_status')
      identifyVerificationModalStatus.setAttribute('value', 'open')
    })
  }

  // QRコードを表示
  const profileSheetIdentifyQRCodeOpener = document.querySelector('.identify-button.identify-button-pending.js-open-identify_verification_modal_pending')
  if (profileSheetIdentifyQRCodeOpener) {
    profileSheetIdentifyQRCodeOpener.addEventListener('click', (event) => {
      event.preventDefault()
      const modalOverlay: HTMLElement = document.querySelector('.js-modal-overlay')
      if (modalOverlay) return modalOverlay.hidden = false
      const identifyModal: HTMLElement = document.querySelector('#js-identify-modal')
      ReactDOM.render(<ModalStandbyPreparation />, identifyModal)
    })
  }

  const openIdentifyModal = () => {
    const identifyModal: HTMLElement = document.querySelector('#js-identify-modal')
    const props: profileProps = JSON.parse(identifyModal.dataset.reactModal) as profileProps
    ReactDOM.render(
      <IdentifyStartModal
        csrf_token={props.csrf_token}
        user_id={props.user_id}
        last_name={props.last_name}
        first_name={props.first_name}
        zip={props.zip}
        prefecture={props.prefecture}
        address_line_1={props.address_line_1}
        address_line_2={props.address_line_2}
        overseas={props.overseas}
        company_name={props.company_name}
        country_name={props.country_name}
      />, identifyModal
    )
  }

  // 本人確認が完了、「今後は非表示」ボタンが押されたとき
  const eternalClose = document.querySelector('.js-identify-eternal-close')
  if (eternalClose) {
    eternalClose.addEventListener('click', (e) => {
      e.preventDefault()
      const identifyNoticeArea: HTMLElement = document.querySelector('.identify-notice-area')
      identifyNoticeArea.style.display = 'none'
      document.cookie = 'dashboard_identify-eternal-close=true'
    })
  }

  // インボイスのモーダルステータス変更を監視するノードを選択
  const invoiceCheckModalStatus = document.getElementById('invoice_check_modal_status')

  // (変更を監視する) オブザーバーのオプション
  const invoiceObserverConfig = { attributes: true, childList: false, subtree: false }

  const invoiceObserverCallback = function() {
    if (invoiceCheckModalStatus.getAttribute('value') === '') {
      return
    }
    const isOpenInputModal = invoiceCheckModalStatus.getAttribute('value') === 'input_modal_open'
    openInvoiceModal(isOpenInputModal)
    invoiceCheckModalStatus.setAttribute('value', '')
  }

  const invoiceObserver = new MutationObserver(invoiceObserverCallback)
  if (invoiceCheckModalStatus) {
    invoiceObserver.observe(invoiceCheckModalStatus, invoiceObserverConfig)
  }

  // インボイスのモーダルを押した時
  const invoiceModalOpenner = document.querySelector('.js-open-invoice_interview_modal')
  if (invoiceModalOpenner) {
    invoiceModalOpenner.addEventListener('click', (event) => {
      event.preventDefault()
      const invoiceCheckModalStatus = document.getElementById('invoice_check_modal_status')
      invoiceCheckModalStatus.setAttribute('value', 'input_modal_open')
    })
  }

  const openInvoiceModal = (isOpenInputModal: boolean) => {
    const invoiceModal: HTMLElement = document.querySelector('#js-invoice-modal')
    const props: InvoiceProps = JSON.parse(invoiceModal.dataset.reactModal) as InvoiceProps
    const openModal = isOpenInputModal ? 'secondModal' : ''
    ReactDOM.render(
      <InvoiceStartModal
        csrf_token={props.csrf_token}
        name={props.name}
        openModal={openModal}
        companyNameFlag={props.companyNameFlag}
      />, invoiceModal
    )
  }
})
