import React, { useState, createContext } from 'react'
import CSSModules from 'react-css-modules'
import styles from './ModalFirstView.module.scss'
import { ModalSecondView } from './ModalSecondView'
import { ModalThirdView } from './ModalThirdView'
import ModalFourthView from './ModalFourthView'
import ModalFifthView from './ModalFifthView'
import ModalNoNumberView from './ModalNoNumberView'
import ModalButton from 'spa/member/components/ModalButton'

export interface InvoiceProps {
  csrf_token: string,
  name: string,
  openModal: string,
  companyNameFlag: boolean
}

export const InvoiceModalValues = createContext({} as {
  invoiceNumber: string,
  setInvoiceNumber: React.Dispatch<React.SetStateAction<string>>
  goNextBtn: boolean,
  setGoNextBtn: React.Dispatch<React.SetStateAction<boolean>>
  errors: string[],
  setErrors: React.Dispatch<React.SetStateAction<string[]>>
  isValid: boolean,
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>
  })

const InvoiceStartModal: React.FC<InvoiceProps> = CSSModules<React.FC<InvoiceProps>>((props: InvoiceProps) => {
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')
  const [goNextBtn ,setGoNextBtn] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [openModal, setOpenedModal] = useState<string>(props.openModal || 'firstModal')
  const [errors, setErrors] = useState<string[]>([])
  const changeModal = (openModal: string) => {
    setOpenedModal(openModal)
  }

  const modalContainer = document.querySelector('.js-modal-container')
  if (!modalContainer) {
    if (openModal === '') {
      setOpenedModal(props.openModal || 'firstModal')
    }
  }

    const handleSubmit = () => {
        fetch('/api/v1/users/invoice_informations', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( {} )
        } )
            .then(response => {
                return response.json()
            })
            .then((json: JSON) => {
                setOpenedModal('noNumberModal')
            })
            .catch( () => {
                setOpenedModal('')
            })
    }

  const onClickNext = () => {
    setErrors([])
    changeModal('secondModal')
  }

    const modalStart = () => {
    return(
      <>
        <div styleName='section-title'><p>インボイス制度に関する状況をお聞かせください。</p></div>
        <div styleName='section-text'>
          <p>インボイス登録番号を取得していますか？</p>
        </div>
        <div styleName='section-btn'>
          <ModalButton buttonType='NEXT' buttonSize='LARGE' onClick={() => onClickNext()} className='first-modal-go-next-btn'>
            インボイス登録番号を取得済み
          </ModalButton>
        </div>
        <div styleName='section-btn'>
          <ModalButton buttonType='BACK' buttonSize='LARGE' onClick={() => handleSubmit()}>
            <span>これから取得する予定、</span><span>取得の予定はない</span>
          </ModalButton>
        </div>
      </>
    )
  }

  return (
    <InvoiceModalValues.Provider
      value={ {
        invoiceNumber, setInvoiceNumber,
        goNextBtn, setGoNextBtn,
        errors, setErrors,
        isValid, setIsValid,
      } }>
      { openModal == 'noNumberModal' && <ModalNoNumberView changeModal={ changeModal }/> }
      { openModal == 'secondModal' && <ModalSecondView changeModal={ changeModal } name={props.name} companyNameFlag={props.companyNameFlag} />}
      { openModal == 'thirdModal' && <ModalThirdView changeModal = { changeModal } name={props.name} />}
      { openModal == 'fourthModal' && <ModalFourthView changeModal = { changeModal } />}
      { openModal == 'fifthModal' && <ModalFifthView changeModal = { changeModal } />}
      { openModal == 'firstModal' &&
        <div styleName='overlay'>
          <div styleName='modal-container' className='js-modal-container'>
            {modalStart()}
          </div>
        </div>
      }
    </InvoiceModalValues.Provider>
  )
}, styles, { allowMultiple: true })

export default InvoiceStartModal
