import React from 'react'
import { StyledModalButton, ModalButtonProps } from './styled'

// NOTES: このコンポーネントは最終的にButtonコンポーネントに集約するため、SPA化後に削除予定です
const Index: React.FC<ModalButtonProps> = (props: ModalButtonProps) => {
  return (
    <StyledModalButton
      buttonType={props.buttonType}
      buttonSize={props.buttonSize}
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
    >
      {props.children}
    </StyledModalButton>
  )
}

export default Index
